.loginWraper{
    height:400px;
    background-color:'white';
}

.loginWraper .title {
    margin-left: auto;
    margin-right: auto; 
    width: 80%; 
    text-align: center;
}

.loginWraper .loginFrm{
    margin-left: auto;
    margin-right: auto; 
    width: 90%; 
}

.loginWraper .login-form {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .login-form-forgot {
    float: right;
  }
  .ant-col-rtl .login-form-forgot {
    float: left;
  }
  .login-form-button {
    width: 100%;
  }
