@import '~antd/dist/antd.css';
@import '~bootstrap/dist/css/bootstrap.css';

.App{
  background-color: white;
}

.ant-layout{

  background-color: white;
}

.ant-layout-header {
  height: 76px;
  padding: 0 50px;
  color: rgba(255, 225, 225, 0.65);
  line-height: 76px;
  background: #001529;
}

.demo-loadmore-list {
  min-height: 100px;
}




.leftSide{
  position: relative;
  background-color: aqua;
  height: 100vh;
}

.leftSide .middle{
  position: relative;
  top:50%;
  transform: translateY(-50%);
  padding-left: 100px;
}




.rightSide{
  background-color: white;
  height: 100vh;

}

.rightSide .top{

  position: relative;
  top:40%;
  transform: translateY(-30%);
  margin: auto;
  padding-left: 30Px;
  padding-right: 30px;
  text-align: center;


}



.rightSide .bottom{

  position: relative;
  top:50%;
  transform: translateY(-50%);
   margin: auto;
  padding-left: 30Px;
  padding-right: 30px;
  text-align: center;
  


}

.notice_container{
  background-color: white;
  color: black;
  font-size: 16px;

}

.notice_container .alink {

  color:blue;
  text-decoration: underline;

}
.sendMsg{

  margin-top: 300px;
  text-align: center;
}

.formTitle{
  font-weight: 600 !important;
}

.ant-form-item-label {
  text-align: left;

  }

  .font_red{
    color:red;
  }

  .font_w400{
    font-weight: 400;
  }

  .font_w600{
    font-weight: 600;
  }

  .font_w800{
    font-weight: 800;
  }

  .font_14{
    font-size: 14px;
  }

  .font_16{
    font-size: 16px;
  }

  .font_12{
    font-size: 12px;
  }

  
  .font20{

    font-size:20px;
}

  .site-page-header_detail {

    border: 1px solid rgb(235, 237, 240);
    margin-top: 50px;
    margin-bottom: 10px;
    width: 800px;
    background-color: #e8e9ef
    }

    .ant-page-header-heading-title {
      line-height: 20px;
   
  }

  .searchTitle{
    font-weight:600;
    margin-right:10px;
}

.itemWrapper{
  display:flex;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.itemLabel{
  width:200px;
  font-weight:600;
}

.memoLabel{
  width:200px;
  font-weight:600;
}
.itemMemo{
  padding:20px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  flex: 1;

}
.jobmemo{
  padding:20px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  flex: 1;
}

.ant-layout-sider-zero-width-trigger{
  top: 16px;
}

.detailTitle{
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 30px;
  text-transform: uppercase;
  
}
.itmetitle{
  margin-top: 10px;
  font-size: 16px;
  font-weight: 600;

}

.itemtext{
  padding-left: 5px;
  padding-top: 10px;
  padding-bottom: 3PX;
  margin-bottom: 10px;
  border-bottom: 1px solid gray;
  
}

.itembox{
  padding: 10px;
  padding-bottom: 10px;
  margin-top: 10px;
  margin-bottom: 30px;
  border: 1px solid gray;
  word-wrap: break-word;

  
}


@media (min-width: 1201px) {

.mobile_container{
  display: none;
}

.pc_container{

  display: block;
}

.mobile_only{
  display: none;
}
.pc_only{

  display: block;

}

.m_logo{
  display: none;
}

.main_header{
  display: inline-block;
  color:white;
  font-size: 40px;
  font-weight: 600;
  margin-right: 10px;
  margin-left: 10px;

}

.main_tile{

    display: inline-block;
    font-size: 35px;
    font-weight: 600;
    padding-left: 16px;
  
}

.logo{
  width: 200px;
}



}

/* Mobile */

@media (max-width: 1200px) {

 
  .pc_container{

    display: none;
  }

  .mobile_container{

    display: block;
  
  }

  .main_tile{
    display: none;
  }

  .main_header{
    display: none;
  }

  .pc_only{
    display: none;
  }

  .mobile_only{
    display: block;
  }


  .searchTitle{
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 10px;

  }
  .memoLabel{
    display: none;
  }

  .itemWrapper{
    display: block;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 90%;
  }

  .itemLabel{
    width:100%;
    font-weight:500;
    margin-bottom: 5px;;
  }

  .pageTitle{
    margin-bottom: 10px;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 600;
  }

  .form-control {
    border-top: 0px solid #ced4da;
    border-left: 0px solid #ced4da;
    border-right: 0px solid #ced4da;
    border-bottom: 1px solid #ced4da;
}

.titleFont{
    display: inline-block;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.2em;
}
.logo{
  display: none;
}

.m_logo{

  margin-left: auto;
  margin-right: auto;
  max-height: 76px;

}
  


}